<template>
  <div class="attend_class_page">
    <div class="left">
      <div class="block">
        <div class="first_title tab">上课班级</div>
        <div
          class="item_title tab"
          v-for="item in classList"
          :key="item.id"
          :class="{ active: item.id == tabClassId }"
          @click="checkClass(item)"
        >
          {{ item.type == 2 ? "小班" : item.type == 1 ? "中班" : "大班" }} ·
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="radio_block tab" @click="handleRadioAll">
          <div class="radio" :class="{ active: allCheckFlag }"></div>
          <div class="title">全部</div>
        </div>
        <div class="radio_block tab" @click="handleCancelRadioAll">
          <div class="radio" :class="{ active: allClearFlag }"></div>
          <div class="title">清空</div>
        </div>
        <div class="radio_block">选择学员</div>
      </div>
      <div class="select">
        <div class="title">
          <span>选择上课老师</span>
        </div>
        <div class="check_block">
          <div
            class="check_block_item tab"
            v-for="item in atteTeacherList"
            :key="item.id"
            @click="item.isCheck = !item.isCheck"
          >
            <div class="check" :class="{ active: item.isCheck }"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="select">
        <div class="title">
          <span>选择学生</span>
        </div>
        <div class="check_block">
          <div
            class="check_block_item"
            v-for="item in atteStudentList"
            :key="item.id"
            @click="item.isCheck = !item.isCheck"
          >
            <div class="check" :class="{ active: item.isCheck }"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="course_block">
        <div class="Tit">
          <!-- <span>选择课程</span> -->
          <!-- <div class="bgnBtn tab" @click="toAttend">开始上课</div> -->
        </div>
        <div class="course_flex_block">
          <div
            class="course_item"
            @click="toAttend(item)"
            :class="{ video_acitve: item.id === tabVideo.id }"
            v-for="item in videoList"
            :key="item.id"
          >
            <div class="bar"></div>
            <div class="title">第{{ item.lessons }}节</div>
            <div class="desc">{{ item.videoname }}</div>
            <div class="look" v-show="item.over"></div>
            <div class="lock" v-show="item.publish === 0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "evaluateComponent",
  components: {},
  data() {
    return {
      atteStudentList: [],
      atteTeacherList: [],
      videoList: [],
      classList: [],
      tabClassId: "",
      tabVideo: {},
      bol: true, //节流阀
    };
  },
  created() {},
  computed: {
    allCheckFlag() {
      let res = true;
      this.atteTeacherList.forEach((item) => {
        if (!item.isCheck) {
          res = false;
        }
      });
      this.atteStudentList.forEach((item) => {
        if (!item.isCheck) {
          res = false;
        }
      });
      return res;
    },
    allClearFlag() {
      let res = true;
      this.atteTeacherList.forEach((item) => {
        if (item.isCheck) {
          res = false;
        }
      });
      this.atteStudentList.forEach((item) => {
        if (item.isCheck) {
          res = false;
        }
      });
      return res;
    },
  },
  mounted() {
    this.getClassList();
  },
  methods: {
    handleRadioAll() {
      this.atteStudentList.forEach((item) => {
        item.isCheck = true;
      });
      this.atteTeacherList.forEach((item) => {
        item.isCheck = true;
      });
    },
    handleCancelRadioAll() {
      this.atteStudentList.forEach((item) => {
        item.isCheck = false;
      });
      this.atteTeacherList.forEach((item) => {
        item.isCheck = false;
      });
    },
    //  获取上课班级
    getClassList() {
      this.api.course
        .findGradesOfTeacherId({
          teacherId: this.$store.state.userInfo.teacherId,
        })
        .then((res) => {
          this.classList = res.data;
          
          let data = {};
          if(this.$route.query.schoolId && this.$route.query.id){
            data = this.$route.query;
            this.tabClassId = this.$route.query.id;
          }else{
            data = res.data[0];
            this.tabClassId = res.data[0].id;
          }
          this.getList(data);
        });
    },
    checkClass(item) {
      this.tabClassId = item.id;
      this.getList(item);
    },
    // 获取教师/学生/视频列表
    getList(res) {
      let data = {
        schoolId: res.schoolId,
        teacherId: this.$store.state.userInfo.teacherId,
        gradeId: res.id,
        // gradeType: res.type,
      };
      this.api.course.findEntryClass(data).then((res) => {
        this.atteStudentList = res.data.atteStudentList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.atteTeacherList = res.data.atteTeacherList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
        this.videoList = res.data.videoList;
      });
    },
    // 上课
    toAttend(item) {
      this.tabVideo = item;
      if (this.$route.query.welfare) {
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              if (res.data !== 5) {
                this.$notice({
                  message: "您不是公益幼儿园，请前往校园中心进行相关教学工作",
                }).isShow();
              } else {
                let stuList = this.atteStudentList.filter((item) => {
                  return item.isCheck;
                }).map((item)=>{
                  return item.id
                });
                let TeachList = this.atteTeacherList.map((item) => {
                  return item.isCheck;
                }).map((item)=>{
                  return item.id
                });

                if (this.$route.query.welfare) {
                  this.$router.push({
                    path: "/classVideo",
                    query: {
                      videoname: item.videoname,
                      gradeId: this.tabClassId,
                      id: item.id,
                      normalUrl: item.normalUrl,
                      videoinfo: item.videoinfo,
                      studentIds: stuList.join(","),
                      teacherId: TeachList.join(","),
                      welfare: this.$route.query.welfare,
                    },
                  });
                } else {
                  this.$router.push({
                    path: "/classVideo",
                    query: {
                      videoname: item.videoname,
                      gradeId: this.tabClassId,
                      id: item.id,
                      normalUrl: item.normalUrl,
                      videoinfo: item.videoinfo,
                      studentIds: stuList.join(","),
                      teacherId: TeachList.join(","),
                    },
                  });
                }
              }
            }
          });
      } else {
        let stuList = this.atteStudentList.filter((item) => {
          return item.isCheck;
        }).map((item)=>{
          return item.id;
        });

        if (!stuList.length) {
          this.$notice({
            message: "请选择学生",
          }).isShow();
          return;
        }

        let TeachList = this.atteTeacherList.filter((item) => {
          return item.isCheck;
        }).map((item)=>{
          return item.id
        });
        if (!TeachList.length) {
          this.$notice({
            message: "请选择老师",
          }).isShow();
          return;
        }
        if (item.publish === 0) {
          this.$notice({
            type: "success",
            message: "敬请期待",
          }).isShow();
          return;
        }
        this.getNormaoInfo(item, stuList, TeachList);
      }
    },
    // 非公益园判断接口
    getNormaoInfo(item, stuList, TeachList) {
      this.api.unit
        .getPlayInfoByToken({ videoId: item.id, definition: "" })
        .then((res) => {
          if (res.flag) {
            this.$router.push({
              path: "/classVideo",
              query: {
                videoname: item.videoname,
                gradeId: this.tabClassId,
                id: item.id,
                normalUrl: item.normalUrl,
                videoinfo: item.videoinfo,
                studentIds: stuList.join(","),
                teacherId: TeachList.join(","),
              },
            });
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.attend_class_page {
  width: 100%;
  padding-top: 73px;
  @include flex(row, center, flex-start);

  .left {
    .block {
      box-sizing: border-box;
      margin-bottom: 13px !important;
      font-family: Alibaba PuHuiTi;
      font-size: 31px !important;

      .first_title {
        width: 266px;
        height: 84px;
        border-top-right-radius: 45px;
        background: #cc1a30;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        line-height: 81px;
      }

      .item_title {
        width: 266px;
        height: 69px;
        background: #f8f5f5;
        font-weight: 400;
        color: #666666;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #cc1a30;
        margin-bottom: 7px;
        @include flex();
      }

      .active {
        color: #cc1a30;
        position: relative;
      }

      .active::after {
        content: "";
        position: absolute;
        top: 20px;
        right: 10px;
        width: 10px;
        height: 29px;
        @include bg("../../assets/img/lesson/leftsideactive.png");
      }
    }
  }

  .right {
    width: calc(100vw - 266px);
    min-width: 1351px;
    margin: 0 186px 0 110px;

    .top {
      width: 100%;
      @include flex(row, flex-end, center);

      .radio_block {
        @include flex();
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #333333;
        margin-left: 32px;

        .radio {
          width: 31px;
          height: 30px;
          border-radius: 50%;
          background: #e1e2e2;
          margin-right: 7px;
        }
      }
    }

    .select {
      margin-bottom: 28px;

      .title {
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #333333;
        line-height: 63px;
        text-align: center;
        border-bottom: 4px solid #bfbfbf;
        margin-bottom: 27px;

        span {
          display: block;
          width: 224px;
          height: 63px;
          border-top-right-radius: 30px;
          background: #f5f6f7;
        }
      }

      .check_block {
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        padding-left: 24px;

        .check_block_item {
          margin-right: 55px;
          margin-bottom: 10px;
          @include flex();

          .check {
            width: 16px;
            height: 16px;
            background: #e1e2e2;
            border-radius: 50%;
            margin-right: 10px;
          }

          .text {
            font-size: 25px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }

    .course_block {
      margin-bottom: 37px;

      span {
        display: block;
        width: 224px;
        height: 63px;
        border-top-right-radius: 30px;
        background: #cc1a30;
        line-height: 63px;
        text-align: center;
        margin-bottom: 27px;
        color: #ffffff;
        font-size: 26px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
      }

      .course_flex_block {
        width: 100%;
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        .course_item {
          width: 286px;
          height: 166px;
          background: #ffffff;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
          box-sizing: border-box;
          padding: 36px 55px 30px;
          margin-right: 69px;
          margin-bottom: 73px;
          position: relative;
          color: #333;
          @include flex(column, space-between, flex-start);

          .bar {
            width: 66px;
            height: 4px;
            background: #333333;
          }

          .title {
            font-size: 24px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
          }

          .desc {
            font-size: 20px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
          }
        }

        .course_item:nth-child(4n) {
          margin-right: 0;
        }
        .video_acitve {
          background: #cc1a30;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
          color: #fff;
          .bar {
            background: #fff !important;
          }
        }
      }
    }
    .Tit {
      display: flex;
      .bgnBtn {
        width: 183px;
        height: 54px;
        background: #3565b5;
        font-size: 25px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        @include flex();
        margin: 0.09rem 0 0.5rem;
      }
    }

    .active {
      background: #cc1a30 !important;
    }

    .look {
      width: 37px;
      height: 37px;
      background: #cc1a30;
      position: absolute;
      box-sizing: 6px 4px 9px;
      box-sizing: border-box;
      top: 0;
      right: 0;
      @include bg("../../assets/img/lesson/look2.png");
    }

    .lock {
      width: 37px;
      height: 37px;
      background: #3565b5;
      position: absolute;
      padding: 4px 7px 7px 6px;
      box-sizing: border-box;
      top: 0;
      right: 0;
      @include bg("../../assets/img/lesson/lock2.png");
    }
  }
}
</style>
